<template>
  <v-container>
    <base-dialog-notification ref="confirm" />
    <base-snackbar-notification ref="mainSnackbar" />
    <v-container id="view-users" tag="section">
      <base-material-card
        color="default"
        icon="mdi-account-outline"
        inline
        class="px-5 py-3"
        :elevation="8"
      >
        <template v-slot:after-heading>
          <div class="display-3 font-weight-light">
            <h1>Contract Templates</h1>
          </div>
        </template>
        <template v-if="contractTemplates">
          <v-container>
            <v-data-table
              :headers="headers"
              :items="contractTemplates.records"
              item-key="id"
              :loading="isLoading"
              :search="search"
              :sort-by.sync="sortBy"
            >
              <template v-slot:top>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" lg="3">
                        <v-btn
                            class="text-right"
                            color="#0a2752"
                            large
                            dark
                        >
                          <h1>Create New Template</h1>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col md="3">
                        <v-text-field v-model="search" label="Search" outlined />
                      </v-col>
                    </v-row>
                    <v-row class="my-2">

                    </v-row>
              </template>
              <template v-slot:item.name="{ item }">
                <h5 color="primary">
                  {{ `${item.name}` }}
                </h5>
              </template>
              <template v-slot:item.jobTitle="{ item }">
                <v-chip label>
                  {{ item.jobTitle }}
                </v-chip>
              </template>
              <template v-slot:item.createdAt="{ item }">
                <h5 color="primary">
                  {{ item.createdAt | getFormattedDate }}
                </h5>
              </template>
              <template v-slot:item.edit="{ item }" class="text-center">
                <v-btn
                    @click="editItem(item)"
                >Edit</v-btn>
              </template>
              <template v-slot:item.delete="{ item }" class="text-center">
                <v-btn @click="deleteContractTemplate(item)">Delete</v-btn>
              </template>
            </v-data-table>
          </v-container>
        </template>
      </base-material-card>
    </v-container>
  </v-container>
</template>

<script>
import { gql } from "apollo-boost";
// import defaultProfile from "@/assets/avatar.png";
export default {
  name: "ViewEmployers",
  data() {
    return {
      statusQuery: "",
      fields: {
        "Full Name": "fullName",
        "Email Address": "email",
        "Created At": "createdAt",
      },
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: "Name",
          value: "name",
          align: "left",
        },
        {
          text: "Job Title",
          value: "jobTitle",
          align: "left",
        },
        {
          text: "Description",
          value: "description",
          align: "left",
        },
        {
          text: "Created",
          value: "createdAt",
          filterable: false,
        },
        {
          sortable: false,
          text: "Edit",
          value: "edit",
          align: "center",
          filterable: false,
        },
        {
          sortable: false,
          text: "Delete",
          value: "delete",
          align: "center",
          filterable: false,
        },
      ],
      dialog: false,
      search: "",
      sortBy: "firstName",
      users: [],
      totalRecords: 0,
      isLoading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          firstName
          lastName
          role
        }
      }
    `,
    contractTemplates: {
      query: gql`
        query GetContractTemplates($limit: Int!, $skip: Int!, $query: JSON!) {
          contractTemplates(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              description
              jobTitle
              createdAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        console.log("isLoading", isLoading);
        this.isLoading = isLoading;
      },
      result(data, key) {
        console.log(data, key);
      },
    },
  },
  computed: {},
  watch: {},
  methods: {
    editItem(item) {
      this.$router.push(`/admin/contracttemplate/${item.id}/edit`);
    },
    async deleteContractTemplate (item) {
      console.log(item)
      this.$apollo.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            deleteContractTemplate(id: $id) {
              id
              deleted
            }
          }
        `,
        variables: {
          id: item.id,
        },
      }).then((res) => {
        console.log(res)
        this.$apollo.queries.contractTemplates.refetch()
        this.$refs.mainSnackbar.open('Contract Template Deleted.', 'success')
      }).catch((err) => {
        console.log(err)
        this.$refs.confirm.open("Error", "There was a problem while deleting the Contract Template. Please try again or contact support.", "error")
      })
    },
    async created() {
      this.$eventHub.$on("user-updated", this.$apollo.queries.users.refetch());
      // this.$eventHub.$on('user-updated', this.refreshData)
    },
    beforeDestroy() {
      this.$eventHub.$off("user-updated");
    },
    refreshData() {
      console.log("Refreshing data for users");
      this.$apollo.queries.users.refetch();
    },
    customFilter(value, search, item) {
      console.log(value, search, item);
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    defaultProfileImage() {
      return defaultProfile;
    },
  },
};
</script>
<style lang="sass">
td a
  text-decoration: none
  cursor: pointer
router-link
  cursor: pointer
</style>
